import React from "react";







class Weather extends React.Component {

componentDidMount() {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(showPosition);
                       }
  function showPosition(position) {
  const lat = position.coords.latitude;
  const lon = position.coords.longitude
  fetch(`https://weather-proxy.freecodecamp.rocks/api/current?lat=${lat}&lon=${lon}`)
  .then(response => response.json())
  .then(data => {
    document.querySelector("#city-weather").innerText = data["name"] + ", " + data["sys"]["country"];
    document.querySelector("#weather-weather").innerText = data["weather"][0]["main"];
    document.querySelector("#icon-weather").setAttribute("src", data["weather"][0]["icon"]);
    document.querySelector("#temperature-weather").innerHTML = Math.round(`${data["main"]["temp"]}`) + "<sup>º</sup>C";
    document.querySelector("#temp-max-weather").innerHTML = "Max: " + Math.round(`${data["main"]["temp_max"]}`) + "<sup>º</sup>C";
    document.querySelector("#temp-min-weather").innerHTML = "Min: " + Math.round(`${data["main"]["temp_min"]}`) + "<sup>º</sup>C";
    document.querySelector("#feels-like-weather").innerHTML = "Feels like: " + `${data["main"]["feels_like"]}` + "<sup>º</sup>C";
    document.querySelector("#humidity-weather").innerHTML = "Humidity: " + `${data["main"]["humidity"]}` + "%";
    
    
  })
  }
}
 
render() {
  return(
    <div className="container-fluid" style={{backgroundColor:"#000324", height:"97.8vh"}}>
      
      <h1 style={{textAlign:"center", paddingTop:"10vh", color:"white"}}>Weather App</h1>
      <div className="container" style={{textAlign:"center", backgroundColor:"#180152", marginTop:"10vh", width: "40vh", height:"50vh", color:"white", borderRadius:"2%"}}>
      <h1 id="city-weather" style={{color:"white", paddingTop:"3vh"}}></h1>
<h3 id="weather-weather" style={{color:"white", marginTop:"3vh"}}></h3>
<img id="icon-weather" style={{height:"9vh", marginTop:"3vh"}}/>
<h4 id="temperature-weather" style={{color:"white", fontSize:"4vh", marginTop:"2vh"}}></h4>
<h5 id="temp-max-weather" style={{color:"white", fontSize:"2vh", marginTop:"5vh", textAlign:"left"}}></h5>
<h5 id="temp-min-weather" style={{color:"white", fontSize:"2vh", marginTop:"1vh", textAlign:"left"}}></h5>
<h5 id="feels-like-weather" style={{color:"white", fontSize:"2vh", marginTop:"-6.7vh", textAlign:"right"}}></h5>
<h5 id="humidity-weather" style={{color:"white", fontSize:"2vh", marginTop:"1vh", textAlign:"right"}}></h5>

</div>
</div>

  )

 }
};

export default Weather;