import React from "react";
import emailjs from "emailjs-com";


const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach(
    (val) => val.length > 0 && (valid = false)
  );
  return valid;
}

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisabled: true,
      buttonSubmit: null,
      firstName: null,
      email: null,
      subject: null,
      message: null,
      errors: {
        firstName: " ",
        email: " ",
        subject: " ",
        message: " "
      }
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm('gmail', 'template_test', e.target, "user_dm9mkfv2VCp2nPXXg4z0v")
    if(validateForm(this.state.errors)) {
      console.info("Valid Form")
    }else{
      console.error("Invalid Form")
    }
    e.target.reset();
  }


  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case "name":
        errors.firstName = 
        value.length < 3 
        ? "Enter your name" 
        : "";
        break;
      case "email": 
        errors.email = 
          validEmailRegex.test(value)
            ? "" 
            : "Enter a valid email address";
        break;
      case "subject": 
        errors.subject = 
          value.length < 3
            ? "Enter a subject at least 3 characters long"
            : "";
        break;
      case "message" :
        errors.message =
        value.length < 10
            ? "Enter your message. It must be at least 10 characters long"
            : "" ;
      default:
        break;
    }

    this.setState({ errors, [name]: value });

    if (
      errors.email.length === 0 &&
      errors.subject.length === 0 &&
      errors.message.length === 0 &&
      errors.firstName.length === 0
    ) {
      this.setState({ isDisabled: false });
    } else {
      this.setState({ isDisabled: true });
    }
  }

  
  render() {
    const {errors} = this.state;
  return (
    <div>
     <div className="container fluid">
       <form onSubmit={this.handleSubmit}>
        <div className="row pt-5 mx-auto">
          <div className="col-8 form-group mx-auto">
            <input type="text" className="form-control" placeholder="Name" name="name" onChange={this.handleChange}/>
            {errors.firstName.length > 0 && 
                <span className='error'>{errors.firstName}</span>}
          </div>
          <div className="col-8 form-group pt-2 mx-auto">
            <input type="email" className="form-control" placeholder="Email Address" name="email" onChange={this.handleChange} noValidate/>
            {errors.email.length > 0 && 
                <span className='error'>{errors.email}</span>}
          </div>
          <div className="col-8 form-group pt-2 mx-auto">
            <input type="text" className="form-control" placeholder="Subject" name="subject" onChange={this.handleChange} noValidate/>
            {errors.subject.length > 0 &&
              <span className="error">{errors.subject}</span>}
          </div>
          <div className="col-8 form-group pt-2 mx-auto">
            <textarea className="form-control" id="" cols="30" rows="8" placeholder="Your message" name="message" onChange={this.handleChange} noValidate></textarea>
              {errors.message.length > 0 &&
              <span className="error">{errors.message}</span>}
          </div>
          <div className="col-8 pt-3 mx-auto">
            <button className="btn btn-dark" disabled={this.state.isDisabled}>Submit</button>
          </div>
        </div>
       </form>
     </div>
   </div>
)
}
}

export default Form;
