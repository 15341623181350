import React from "react";
import SearchBar from "./SearchBar";
import youtube from "../apis/Youtube";
import VideoList from "./VideoList";
import VideoDetail from "./VideoDetail";

class Youtube extends React.Component {

    state= { videos: [], selectedVideo: null };

    componentDidMount() {
     this.onTermSubmit("Eletronic Music");
    };

    onTermSubmit = async (term) => {
        const response = await youtube.get("/search", { //Esta é uma outra maneira de usar o axyos
            params: {
                q: term
            }
        });

        this.setState({
            videos: response.data.items,
            selectedVideo: response.data.items[0]
        });

    };

    onVideoSelect = (video) => {
      this.setState({selectedVideo: video});
    };

    render() {
       return (
        <div 
       className="container">
           <SearchBar onFormSubmit={this.onTermSubmit}/>
           <div className="grid">
            <div className="row">
             <div className="col-xl-9">
           <VideoDetail video={this.state.selectedVideo}/>
             </div>
              <div className="col-xl-3">
           <VideoList onVideoSelect={this.onVideoSelect} videos={this.state.videos} />
              </div>
             </div>
            </div>
          </div>
       );
    }
}

export default Youtube;