import React from "react";
import Styles from "./playgroundStyles.css";

class Playground extends React.Component{
  render(){
    return(
      <div className="container">
        <div>
          <h1 className="P">P</h1><h1 className="L">L</h1><h1 className="A">A</h1><h1 className="Y">Y</h1>
          <h1 className="G">G</h1><h1 className="R">R</h1><h1 className="O">O</h1><h1 className="U">U</h1><h1 className="N">N</h1><h1 className="D">D</h1>
        </div>
        <div className="ball">
          <h5 style={{textAlign:"center", marginTop:"3vh"}}>Play With Me!</h5>
        </div>
        <div className="mySquare"></div>
        <div className="react-logo">
          <div className="react-logo__circle">
          </div>
          <div className="react-logo__ellipse">
          </div>    
          <div className="react-logo__ellipse_2">
          </div>    
          <div className="react-logo__ellipse_3">
          </div>  
          <div className="message">Open this section in your desktop/laptop <br/> to enjoy the full experience!</div>      
        </div>
      </div>
    );
  }
}

export default Playground;