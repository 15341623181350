import React from "react";
import Form from "./Form";




class Contacts extends React.Component{
  
  render(){  
    return(
      <div className="container">
        <div className="row pt-5 mx-auto mt-5">
          <div className="col-8 mx-auto">
            <h1>Get in Touch!</h1>
          </div>
        </div>
        <div className="row pt-3 mx-auto">
          <div className="col-8 mx-auto">
            <h5>If you wanna talk with me, fill the form below and I'll reply in less than 24hours!</h5>
          </div>
        </div>
        <div>
         <Form />
         <div className="row pt-5 mx-auto">
           <div className="col-8 mx-auto">
         <h1>Lets get social!</h1>
         </div>
         </div>
         <div className="row pt-3 mx-auto">
           <div className="col-8 mx-auto">
         <h5>Follow me on LinkedIn or GitHub to keep in touch with my work.</h5>
         </div>
         </div>
          <div className="row justify-content-center pt-5">
          <div className="col-xl-4 col-5 col-md-5">
            <a href="https://linkedin.com/in/RMartins11" target="_blank">
              <i className="massive linkedin icon"></i>
            </a>
            </div>
            <div className="col-xl-2 col-5 col-md-3">
            <a href="https://github.com/RMartins11" target="_blank">
              <i className="massive github square icon centered" style={{ color: "black", height:"3vh"}}></i>
            </a>
            </div>
            
          </div>
        </div>
      </div>
    );
  }
}


export default Contacts;
