import React from "react";
import styled from "styled-components";
import Game from "./Game";



function TicTacToe() {
  return (
     <div>
       <div className="container" style={{backgroundColor:"#197528", marginTop:"25vh",width:"50vh", height:"60vh",}}>
      <Main>   
      <p style={{fontSize:"5vh"}}>Tic Tac Toe Game</p>   
      <Game />
      </Main>
      </div>
      </div>
    
  );
}

const Main = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
  padding-top: 5vh;
  font-family: "Reggae One", cursive;
  color: #ebebeb;
  @media (max-width: 350px) {
    text-align: center;
  }
`;


export default TicTacToe;