import React from "react";
import { Animated } from "react-animated-css";
import styles from "./styles.css";
import MyCarousel from "./Carousel/Carousel";


class MainPage extends React.Component{
  render(){
    return(
      <div className="container" style={{textAlign: "center", marginTop: "9vh"}}>
        <Animated animationIn="bounceInDown" animationInDuration="3000" isVisible="true">
        <h1 style={{fontSize: "10vh"}}>Hello!</h1>
        </Animated>
        <Animated animationIn="fadeIn" animationInDelay="2000" animationInDuration="5000" isVisible="true">
        <h2>I'm Ricardo, a web developer</h2>
        </Animated>
        <Animated animationIn="pulse" animationInDelay="5000" animationInDuration="1000" isVisible="true">
        <img className="img-responsive" src="https://i.imgur.com/umG81NW.png" alt="profile picture"></img>
        </Animated>
        <Animated animationIn="fadeIn" animationInDelay="4000" animationInDuration="5000" isVisible="true">
        <h2>Click the menu or the images below to know more about me and my work!</h2>
        </Animated>
        <MyCarousel />
      </div>
    );
  }
}

export default MainPage;