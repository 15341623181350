import React from "react";

class SearchBar extends React.Component{

    state = {term: ""};

    onInputChange = event => {
       this.setState({term: event.target.value})
    };

    onFormSubmit = event => {
       event.preventDefault(); //Serve para impedir que o browser procure quando o utilizador está a escrever

       this.props.onFormSubmit(this.state.term);
    };

    render() {
        return (
        <div className="search-bar ui segment" style={{ marginTop:"5vh"}}>
            <form onSubmit={this.onFormSubmit} className="ui form">
                <div className="field">
                    <label>Video Search</label>
                    <input 
                    type="text" 
                    value={this.state.term}
                    onChange={this.onInputChange}
                    />
                </div>
            </form>
            </div>
        )
    }
};

export default SearchBar;