import styled from "styled-components";

export default styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30vh;
  width: 80vh;
  background-color: #00c5e8;
  color: #00c5e8;
  margin-top: 8vh;
  font-size: 4em;
`;