import React from "react";
import { Dropdown, Menu } from 'semantic-ui-react';
import { Link } from "react-router-dom";

const NavBar = () => (
  <div className="fixed-top">
    <Menu size="massive"attached='top' style={{ backgroundColor: "#00c5e8", height: "7vh"}}>
      <Dropdown item icon='big bars icon'>
        <Dropdown.Menu>
          <Dropdown.Item>
            <Link to="/" style={{ color: "black", textDecoration: "none", padding:"1.3vh 4vh 1.3vh 1vh", marginLeft:"-2vh"}}>Home</Link>
          </Dropdown.Item>  
          <Dropdown.Item>
            <Link to="/about" style={{ color: "black", textDecoration: "none", padding:"1.3vh 4vh 1.3vh 1vh", marginLeft:"-2vh"}}>About</Link>
          </Dropdown.Item>
          <Dropdown.Item>
      <Dropdown text='Work' style={{padding:"0.5vh 2vh 0.5vh 1vh", marginLeft:"-2vh"}}>
        <Dropdown.Menu>
          <Dropdown.Item>
            <Link to="/work/calculator" style={{ color: "black", textDecoration: "none", padding:"1.6vh 6vh 1.6vh 1vh", marginLeft:"-2vh"}}>Calculator</Link>
          </Dropdown.Item>
          <Dropdown.Item>
            <Link to="/work/drumpad" style={{ color: "black", textDecoration: "none", padding:"1.6vh 6.5vh 1.6vh 1vh", marginLeft:"-2vh"}}>Drumpad</Link>
          </Dropdown.Item>
          <Dropdown.Item>
            <Link to="/work/images" style={{ color: "black", textDecoration: "none", padding:"1.6vh 3.5vh 1.6vh 1vh", marginLeft:"-2vh"}}>Image Search</Link>
          </Dropdown.Item>
          <Dropdown.Item>
            <Link to="/work/playground" style={{ color: "black", textDecoration: "none", padding:"1.6vh 5.5vh 1.6vh 1vh", marginLeft:"-2vh"}}>Playground</Link>
          </Dropdown.Item>
          <Dropdown.Item>
            <Link to="/work/quote-machine" style={{ color: "black", textDecoration: "none", padding:"1.6vh 1vh 1.6vh 1vh", marginLeft:"-2vh"}}>Quote Generator</Link>
          </Dropdown.Item>
          <Dropdown.Item>
            <Link to="/work/simon-game" style={{ color: "black", textDecoration: "none", padding:"1.6vh 1vh 1.6vh 1vh", marginLeft:"-2vh"}}>Simon Game</Link>
          </Dropdown.Item>
          <Dropdown.Item>
          <Link to="/work/tic-tac-toe-game" style={{ color: "black", textDecoration: "none", padding:"1.6vh 1vh 1.6vh 1vh", marginLeft:"-2vh"}}>Tic Tac Toe Game</Link>
          </Dropdown.Item>
          <Dropdown.Item>
            <Link to="/work/youtube" style={{ color: "black", textDecoration: "none", padding:"1.6vh 4.7vh 1.6vh 1vh", marginLeft:"-2vh"}}>Youtube Lite</Link>
          </Dropdown.Item>
          <Dropdown.Item>
            <Link to="/work/weather" style={{ color: "black", textDecoration: "none", padding:"1.6vh 4.7vh 1.6vh 1vh", marginLeft:"-2vh"}}>Weather App</Link>
          </Dropdown.Item>
          <Dropdown.Item>
            <Link to="/work/wikipedia" style={{ color: "black", textDecoration: "none", padding:"1.6vh 3vh 1.6vh 1vh", marginLeft:"-2vh"}}>Wikipedia Lite</Link>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
          </Dropdown.Item>
          <Dropdown.Item>
            <Link to="/contacts" style={{ color: "black", textDecoration: "none", padding:"1.3vh 2vh 1.3vh 1vh", marginLeft:"-2vh"}}>Contacts</Link>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Menu>
  </div>
);

export default NavBar;