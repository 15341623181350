import axios from "axios";

const KEY = "AIzaSyB_RhGur2N1exML5CJ2Nmqr2zQIWcHgdWs";

export default axios.create({
  baseURL: "https://www.googleapis.com/youtube/v3",
  params: {
      part: "snippet",
      type: "video",
      maxResults: 5,
      key: KEY
  }
});