import React from "react";
import { Router, Route, Switch } from "react-router-dom";

import history from "../history";
import NavBar from "./NavBar";
import MainPage from "./MainPage";
import Contacts from "./Contacts";
import About from "./About";
import Youtube from "../projects/youtube/components/Youtube";
import Wikipedia from "../projects/wikipedia/Wikipedia";
import Images from "../projects/images/components/App";
import Calculator from "../projects/calculator/Calculator";
import Playground from "../projects/playground/Playground";
import Drumpad from "../projects/drumpad/Drumpad";
import QuoteMachine from "../projects/quoteMachine/QuoteMachine";
import SimonGame from "../projects/simon-game/simon-game";
import TicTacToe from "../projects/tic-tac-toe-game/TicTacToe";
import Weather from "../projects/weather/Weather";


class App extends React.Component{
  render() {
    return (
      <div className="ui-container">
        <Router history={history}>
          <div>
          <NavBar/>
          <div>
            <br/>
            <Switch>
              <Route path="/" exact component={MainPage} />
              <Route path="/about" exact component={About} />
              <Route path="/contacts" exact component={Contacts} />
              <Route path="/work/youtube" exact component={Youtube} />
              <Route path="/work/wikipedia" exact component={Wikipedia} />
              <Route path="/work/images" exact component={Images} />
              <Route path="/work/calculator" exact component={Calculator} />
              <Route path="/work/playground" exact component={Playground} />
              <Route path="/work/drumpad" exact component={Drumpad} />
              <Route path="/work/quote-machine" exact component={QuoteMachine} />
              <Route path="/work/simon-game" exact component={SimonGame} />
              <Route path="/work/tic-tac-toe-game" exact component={TicTacToe} />
              <Route path="/work/weather" exact component={Weather} />
            </Switch>
          </div>
          </div>
        </Router>
      </div>
    );
  }
}

export default App;