import React from "react";
import { Progress } from "semantic-ui-react";
import styles from "./styles.css";

const lineStyle = {
  fontSize: "2.5vh"
};

class About extends React.Component{
  render(){
    return(
      <div className="container-fluid">
      <div className="row justify-content-center">
        <h1 style={{textAlign: "center", marginTop: "6vh"}}>About me</h1>
        <div className="col-xl-5 mb-4 col-10">
        <div className="ui centered raised card" style={{marginTop:"1vh", width: "50vh"}}>
          <div className="content">
        <i className="quote left icon" style={{ color: "#00c5e8", marginTop:"0.5vh"}}></i>
        <div className="line" style={{ marginTop:"2vh", fontSize:"2.5vh"}}>My name is Ricardo, I'm 23 and I'm based in Lisbon, Portugal. I'm a former financial assistant who decided to become a web developer.</div>
        <br/>
        <div className="line" style={lineStyle}>I've started this journey in FreeCodeCamp, where since the very beginning I knew I wanted to dedicate to Front End.</div>
        <br/>
        <div className="line" style={lineStyle}>I consider myself a proactive, team-player person. I've also a great ability to learn and adapt to the circumstances and needings of the environment that surrounds me.</div>
        <br/>
        <div className="line" style={lineStyle}>I'm available to do freelance jobs or join any team worldwide through remote work.</div>
        <br/>
        <div className="line" style={{marginBottom:"1vh", fontSize:"2.5vh"}}>If you want to know more about me and talk, go to my Contacts section and get in touch!</div>
        <i className="right floated quote right icon" style={{ color: "#00c5e8", marginBottom:"0.5vh"}}></i>
        <br/>
          </div>
        </div>
        </div>
        <div className="col-xl-5 mb-4 col-10">
        <div className="ui centered raised card" style={{marginTop:"1vh", width: "50vh"}}>
          <h2 style={{textAlign:"center", paddingTop:"1vh"}}>Skills</h2>
          <div className="content" style={{paddingTop:"1vh", paddingBottom:"1vh"}}>
          <h5>HTML5</h5>
          <div class="progress">
  <div className="progress-bar bg-info progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" style={{width: "85%"}}></div>
</div>
          </div>
          <div className="content" style={{paddingTop:"1vh", paddingBottom:"1vh"}}>
          <h5>CSS3</h5>
          <div class="progress">
  <div className="progress-bar bg-info progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" style={{width: "85%"}}></div>
</div>
          </div>
          <div className="content" style={{paddingTop:"1vh", paddingBottom:"1vh"}}>
          <h5>CSS Frameworks</h5>
          <div class="progress">
  <div className="progress-bar bg-info progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" style={{width: "85%"}}></div>
</div>
          </div>
          <div className="content" style={{paddingTop:"1vh", paddingBottom:"1vh"}}>
          <h5>Javascript</h5>
          <div class="progress">
  <div className="progress-bar bg-info progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{width: "75%"}}></div>
</div>
          </div>
          <div className="content" style={{paddingTop:"1vh", paddingBottom:"1vh"}}>
          <h5>React</h5>
          <div class="progress">
  <div className="progress-bar bg-info progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{width: "75%"}}></div>
</div>
          </div>
          <div className="content" style={{paddingTop:"1vh", paddingBottom:"1vh"}}>
          <h5>Redux</h5>
          <div class="progress">
  <div className="progress-bar bg-info progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style={{width: "65%"}}></div>
</div>
          </div>
          <div className="content" style={{paddingTop:"1vh", paddingBottom:"1vh"}}>
          <h5>Bootstrap</h5>
          <div class="progress">
  <div className="progress-bar bg-info progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style={{width: "65%"}}></div>
</div>
          </div>
          <div className="content" style={{paddingTop:"1vh", paddingBottom:"1vh"}}>
          <h5>REST APIs</h5>
          <div class="progress">
  <div className="progress-bar bg-info progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style={{width: "60%"}}></div>
</div>
          </div>
          <div className="content" style={{paddingTop:"1vh", paddingBottom:"1vh"}}>
          <h5>GIT</h5>
          <div class="progress">
  <div className="progress-bar bg-info progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style={{width: "60%"}}></div>
</div>
          </div>
          <div className="content" style={{paddingTop:"1vh", paddingBottom:"1vh"}}>
          <h5>JQuery</h5>
          <div class="progress">
  <div className="progress-bar bg-info progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style={{width: "55%"}}></div>
</div>
          </div>
          <div className="content" style={{paddingTop:"1vh", paddingBottom:"1vh"}}>
          <h5>Nodejs</h5>
          <div class="progress">
  <div className="progress-bar bg-info progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100" style={{width: "30%"}}></div>
</div>
          </div>
          <div className="content" style={{paddingTop:"1vh", paddingBottom:"1vh"}}>
          <h5>JSON</h5>
          <div class="progress">
  <div className="progress-bar bg-info progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100" style={{width: "30%"}}></div>
</div>
          </div>
        </div>
        </div>
      </div>
      </div>
    );
  }
}

export default About;