import React from "react";
import Carousel from 'react-elastic-carousel';
import Item from "./Item";
import { Link } from "react-router-dom";
import style from "./carouselStyles.css";

class MyCarousel extends React.Component{


  render(){
    return(
      <div>
        <Carousel enableAutoPlay="true" autoPlaySpeed="5000">
        <Item><Link to="/work/youtube"><img src="https://i.imgur.com/yXWp0gD.png" style={{height: "29vh", width: "79vh"}}></img></Link></Item>
        <Item><Link to="/work/quote-machine"><img src="https://i.imgur.com/EvqGB7u.png" style={{height: "29vh", width: "79vh"}}></img></Link></Item>
        <Item><Link to="/work/calculator"><img src="https://i.imgur.com/1NZDGuD.png" style={{height: "29vh", width: "79vh"}}></img></Link></Item>
        <Item><Link to="/work/images"><img src="https://i.imgur.com/ugK6gpF.png" style={{height: "29vh", width: "79vh"}}></img></Link></Item>
        <Item><Link to="/work/drumpad"><img src="https://i.imgur.com/ZUZPIZ8.png" style={{height: "29vh", width: "79vh"}}></img></Link></Item>
        <Item><Link to="/work/wikipedia"><img src="https://i.imgur.com/TCE2QMN.png" style={{height: "29vh", width: "79vh"}}></img></Link></Item>
        <Item><Link to="/work/playground"><img src="https://i.imgur.com/wfkrhOZ.png" style={{height: "29vh", width: "79vh"}}></img></Link></Item>
        </Carousel>
      </div>
    )
  }
}

export default MyCarousel